
































import { Component } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';

@Component
export default class ConfigImageIndexComponent extends BaseComponent {
    get SelectedKeys(): string {
        return this.$route.name || '';
    }
}

